<app-header></app-header>

<app-main-carousel></app-main-carousel>

<nav class="navAside">
    <div class="navAsideContent">
        <a href="#services" class="flexboxHorizontalAlign">
            <div class="dot">
                <h5 style="color: #A41D00 !important; position:relative; left: -75px; bottom: 6.5px; opacity: 0; text-decoration: none;" class="hvr-grow">Services</h5>
            </div>
        </a>
        <a href="#knowledges" class="flexboxHorizontalAlign">
            <div class="dot">
                <h5 style="color: #A41D00 !important;position:relative; left: -115px; bottom: 6.5px; opacity: 0; text-decoration: none;">Compétences</h5>
            </div>
        </a>
        <a href="#projects" class="flexboxHorizontalAlign">
            <div class="dot">
                <h5 style="color: #A41D00 !important;position:relative; left: -65px; bottom: 6.5px; opacity: 0; text-decoration: none;">Projets</h5>
            </div>
        </a>
        <a href="#pricing" class="flexboxHorizontalAlign">
            <div class="dot">
                <h5 style="color: #A41D00 !important;position:relative; left: -50px; bottom: 6.5px; opacity: 0; text-decoration: none;">Tarifs</h5>
            </div>
        </a>
        <a href="#about" class="flexboxHorizontalAlign">
            <div class="dot">
                <h5 style="color: #A41D00 !important;position:relative; left: -78px; bottom: 6.5px; white-space: nowrap; opacity: 0; text-decoration: none;">À propos</h5>
            </div>
        </a>
        <a href="#contact" class="flexboxHorizontalAlign">
            <div class="dot">
                <h5 style="color: #A41D00 !important;position:relative; left: -68px; bottom: 6.5px; opacity: 0; text-decoration: none;">Contact</h5>
            </div>
        </a>
    </div>
</nav>


<section id="services" class="flexboxVerticalAlign" data-navlink="services">

    <div class="sectionTitleDiv flexboxVerticalAlign">
        <h2 data-aos="zoom-in">Mes Services</h2>
        <h3 data-aos="zoom-in">Une multitude de possibilités</h3>
    </div>

    <div class="row itemsContainer" style="margin-left: 0px !important; margin-right: 0px !important;">
        <div class="serviceDiv hvr-float">
            <div class="textServiceDiv shadowserviceDiv flexboxHorizontalAlign" data-aos="fade-down">
                <img src="../../assets/img/pen.png" class="serviceIcon hvr-grow">
                <h3 style="color: white !important;" class="customFont serviceTitle">
                    Web Design
                </h3>
            </div>
            <div class="descriptionServiceDiv shadowserviceDiv customSize6 fl flexboxVerticalAlignStart" data-aos="fade-up">
                <br> 
                Votre identité de marque est créée sur mesure pour répondre aux besoins de votre cible.<br>
                <br>
                <br>
                • Design de site internet<br>
                • Application web & mobile<br>
                • Mise en page de contenu
            </div>
        </div>
        <div class="serviceDiv hvr-float">
            <div class="textServiceDiv shadowserviceDiv flexboxHorizontalAlign" data-aos="fade-down">
                <img src="../../assets/img/settings.png" class="serviceIcon hvr-grow">
                <h3 style="color: white !important;" class="customFont">
                    Développement Web
                </h3>
            </div>
            <div class="descriptionServiceDiv shadowserviceDiv customSize6 flexboxVerticalAlignStart" data-aos="fade-up">
                <br> 
                La création de site web est indispensable pour améliorer la visibilité de ses activités.<br>
                Le développement de votre site web est réalisé pour répondre aux standards web du W3C. 
                <br>
                <br>
                • HTML5 & CSS3 <br>
                • Design responsive <br> 
                (adaptable ordinateur, tablette, smartphone) 
            </div>
        </div>
        <div class="serviceDiv hvr-float">
            <div class="textServiceDiv shadowserviceDiv flexboxHorizontalAlign" data-aos="fade-down">
                <img src="../../assets/img/checked.png" class="serviceIcon hvr-grow">
                <h3 style="color: white !important;" class="customFont serviceTitle">
                    Référencement
                </h3>
            </div>
            <div class="descriptionServiceDiv shadowserviceDiv customSize6 flexboxVerticalAlignStart" data-aos="fade-up">
                <br> 
                Votre site internet est développé avec pour objectif d’apparaître dans les premières pages sur les moteurs de recherche.
                <br> 
                <br> 
                • temps de chargement des pages optimisés<br> 
                • Intégration des différents réseaux sociaux
            </div>
        </div>
        <div class="serviceDiv hvr-float">
            <div class="textServiceDiv shadowserviceDiv flexboxHorizontalAlign" data-aos="fade-down">
                <img src="../../assets/img/reload.png" class="serviceIcon hvr-grow">
                <h3 style="color: white !important;" class="customFont serviceTitle">
                    Intégration Web
                </h3>
            </div>
            <div class="descriptionServiceDiv shadowserviceDiv customSize6 flexboxVerticalAlignStart" data-aos="fade-up">
                <br> 
                Création de contenue grâce à des maquettes en lignes pour inspiration graphique<br>
                respectueuses des standards du Web 
            </div>
        </div>
        <div class="serviceDiv hvr-float">
            <div class="textServiceDiv shadowserviceDiv flexboxHorizontalAlign" data-aos="fade-down">
                <img src="../../assets/img/plane.png" class="serviceIcon hvr-grow">
                <h3 style="color: white !important;" class="customFont serviceTitle">
                    Hébergement
                </h3>
            </div>
            <div class="descriptionServiceDiv shadowserviceDiv customSize6 flexboxVerticalAlignStart" data-aos="fade-up">
                <br> 
                Votre site internet est hébergé sur un serveur puissant, robuste, supportant la montée en charge et situé au plus proche de vos visiteurs.
                <br> 
            </div>
        </div>
        <div class="serviceDiv hvr-float">
            <div class="textServiceDiv shadowserviceDiv flexboxHorizontalAlign" data-aos="fade-down">
                <img src="../../assets/img/tools.png" class="serviceIcon hvr-grow">
                <h3 style="color: white !important;" class="customFont serviceTitle">
                    Maintenance
                </h3>
            </div>
            <div class="descriptionServiceDiv shadowserviceDiv customSize6 flexboxVerticalAlignStart" data-aos="fade-up">
                <br> 
                Un site internet en bonne santé est un site internet mis à jour.<br> 
                Les forfaits de maintenance proposés sont le fruits d’années d’expérience et des solutions 
                apportées à mes clients.
                <br> 
                <br> 
                • maintenance WordPress<br> 
                • maintenance spécifique
            </div>
        </div>
    </div>

</section>

<div id="parallaxBackground" class="rippleDiv">
    <div style="height:300px;">&nbsp;</div>
</div>


<section id="knowledges" class="flexboxVerticalAlign" data-navlink="knowledges" style="position: relative;">
    <div class="sectionTitleDiv flexboxVerticalAlign">
        <h2 style="color: white !important;" data-aos="zoom-in">Mes Compétences</h2>
        <h3 style="color: #E1E1E1 !important;" data-aos="zoom-in">Toujours à votre disposition</h3>
    </div>


    
<div class="container-fluid">
  <div class="row">
        <div class="col flexboxVerticalAlign colContact">
            <h3 data-aos="zoom-in" style="margin-bottom: 60px; margin-top: 30px; text-transform: none !important;color: white !important;">Compétences</h3>
            <ul class="flexboxVerticalAlign skillsList" data-aos="fade-up">
                <li class="customSize4 flexboxHorizontalAlignStart">
                    <img src="../../assets/img/star.png" class="star hvr-grow">
                    <div class="flexboxVerticalAlignStart skillsTextContainer">  
                        <a class="skillList">Gestion de projets web</a> 
                        <a style="color: #E1E1E1 !important;" class="skillList">Site vitrine, E-commerce, site personnalisé...</a>
                    </div>             
                </li>
                <li class="customSize4 flexboxHorizontalAlignStart">
                    <img src="../../assets/img/star.png" class="star hvr-grow">
                    <div class="flexboxVerticalAlignStart skillsTextContainer">  
                        <a class="skillList">Conception responsive</a>
                        <a style="color: #E1E1E1 !important;" class="skillList">Sur tablette, mobile, ordinateur, application web...</a>
                    </div>
                </li>
                <li class="customSize4 flexboxHorizontalAlignStart">
                    <img src="../../assets/img/star.png" class="star hvr-grow">
                    <div class="flexboxVerticalAlignStart skillsTextContainer">  
                        <a class="skillList"> CMS ou interface administration</a>
                        <a style="color: #E1E1E1 !important;" class="skillList"> Wordpress ou interface personnalisée</a>
                    </div>
                </li>
                <li class="customSize4 flexboxHorizontalAlignStart">
                    <img src="../../assets/img/star.png" class="star hvr-grow">
                    <div class="flexboxVerticalAlignStart skillsTextContainer">  
                        <a class="skillList"> Intégration Web</a>
                        <a style="color: #E1E1E1 !important;" class="skillList"> Intégration de templates au choix</a>
                    </div>
                </li>
                <li class="customSize4 flexboxHorizontalAlignStart">
                    <img src="../../assets/img/star.png" class="star hvr-grow">
                    <div class="flexboxVerticalAlignStart skillsTextContainer">  
                        <a class="skillList"> Demande sur mesure</a>
                        <a style="color: #E1E1E1 !important;" class="skillList"> En fonction du client et des demandes</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col flexboxVerticalAlign colContact" data-aos="fade-up">
            <h3 style="margin-bottom: 60px; margin-top: 30px; text-transform: none !important;color: white !important;" data-aos="zoom-in">Compétences en développement</h3>
                <div class="bar">
                  <div class="skill customSize2">HTML & CSS</div>
                  <div class="bar-outer">
                    <div class="speech-bubble html">90%</div>
                    <div class="bar-inner ht"></div>
                  </div>
                </div>
                <div class="bar">
                  <div class="skill customSize2">Javascript</div>
                  <div class="bar-outer">
                    <div class="speech-bubble jquery">90%</div>
                    <div class="bar-inner jq"></div>
                  </div>
                </div>
                <div class="bar">
                  <div class="skill customSize2">Jquerry</div>
                  <div class="bar-outer">
                    <div class="speech-bubble sketch">70%</div>
                    <div class="bar-inner sk"></div>
                  </div>
                </div>
                <div class="bar">
                  <div class="skill customSize2">C++/ C#</div>
                  <div class="bar-outer">
                    <div class="speech-bubble photo">40%</div>
                    <div class="bar-inner ph"></div>
                  </div>
                </div>
                <div class="bar">
                  <div class="skill customSize2">MySQL</div>
                  <div class="bar-outer">
                    <div class="speech-bubble illustrator">60%</div>
                    <div class="bar-inner il"></div>
                  </div>
                </div>
                <div class="bar">
                  <div class="skill customSize2">noSQL</div>
                  <div class="bar-outer">
                    <div class="speech-bubble indesign">80%</div>
                    <div class="bar-inner in"></div>
                  </div>
                </div>
                <div class="bar">
                  <div class="skill customSize2">PHP</div>
                  <div class="bar-outer">
                    <div class="speech-bubble php">25%</div>
                    <div class="bar-inner pl"></div>
                  </div>
                </div>
        </div>
    </div>
</div>
</section>


<section id="projects" data-navlink="projects" style="position: relative !important;">

    <div id="particles-js"></div>

    <div class="sectionTitleDiv flexboxVerticalAlign">
        <h2 data-aos="zoom-in">Mes Projets</h2>
        <h3 data-aos="zoom-in">Les projets que j'ai réalisé</h3>

        <div class="container" style="min-height: 540px;margin-top: 100px !important;">
            <div class="row itemsContainer animatable fadeInUp">
                <app-project-div *ngFor="let projectDiv of projectDivList" [src]="projectDiv.imageSRC" [URL]="projectDiv.URL" [firstText]="projectDiv.firstText" [secondText]="projectDiv.secondText" [description]="projectDiv.description">
                </app-project-div>
            </div>
        </div>
    </div>

</section>

<div class="data">
    <div class="container flexboxVerticalAlign" style="margin-bottom: 100px; margin-top: 100px;">
        <h2 style="color: white;" data-aos="zoom-in"> Dans le monde</h2>
        <h3 style="color: #E1E1E1 !important;" data-aos="zoom-in">Environ...</h3>
    </div>
    <div class="flexboxHorizontalAlign" style="width: 100%;">
        <div class="row" style="margin-right: 0px !important; margin-left: 0px !important;">
            <div class="col-md" style="min-width: 343px !important;">
                <div class="dataDiv flexboxSpaceBetweenColumnAlign hvr-float">
                    <div class="dataTitle flexboxHorizontalAlign" data-aos="fade-up">Nombre de site <br> en Milliards</div>
                    <div class="flexboxVerticalAlign">
                        <h2 class="dataValue count" id="firstValue">2 </h2>
                        <img src="../../assets/img/internet.png" class="dataIcon">
                    </div>
                </div>
            </div>
            <div class="col-md" style="min-width: 343px !important;">
                <div class="dataDiv flexboxSpaceBetweenColumnAlign hvr-float">

                    <div class="dataTitle flexboxHorizontalAlign" data-aos="fade-up">Recherches google <br> par seconde</div>
                    <h2 class="dataValue count">65000</h2>
                    <img src="../../assets/img/loupe.png" class="dataIcon">
                </div>
            </div>
            <div class="col-md" style="min-width: 343px !important;">
                <div class="dataDiv flexboxSpaceBetweenColumnAlign hvr-float">

                    <div class="dataTitle flexboxHorizontalAlign" data-aos="fade-up">Internautes <br> en Milliards</div>
                    <h2 class="dataValue count">4</h2>
                    <img src="../../assets/img/internetUser.png" class="dataIcon">
                </div>
            </div>
            <div class="col-md" style="min-width: 343px !important;">
                <div class="dataDiv flexboxSpaceBetweenColumnAlign hvr-float">

                    <div class="dataTitle flexboxHorizontalAlign" data-aos="fade-up">Objets connectés <br> en millions</div>
                    <h2 class="dataValue count">10</h2>
                    <img src="../../assets/img/smartphone.png" class="dataIcon">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="parallaxVideo flexboxVerticalAlign">
    <video autoplay muted loop id="video">
<source src="../assets/videos/video1.mp4" type="video/mp4">
</video>
    <h2 style="color: white;" class="tlt">"Le travail vaut souvent l'inspiration."</h2>
</div>



<section id="pricing" data-navlink="pricing" class="flexboxVerticalAlign">

    <img id="book" src="../../assets/img/book.png" class="parallaxScroll2">
    <img id="laptop2" src="../../assets/img/laptop2.png" class="parallaxScroll2">
    <img id="laptop" src="../../assets/img/laptop.png" class="parallaxScroll3">
    <img id="keyboard" src="../../assets/img/keyboard.png" class="parallaxScroll">
    <img id="mouse" src="../../assets/img/mouse.png" class="parallaxScroll">
    <img id="stain" src="../../assets/img/stain.png" class="parallaxScroll3">
    <img id="cup" src="../../assets/img/cup.png" class="parallaxScroll2">
    <img id="pencil" src="../../assets/img/pencil.png" class="parallaxScroll2">

    <div class="sectionTitleDiv flexboxVerticalAlign">
        <h2 style="color: white !important;" data-aos="zoom-in">Mes prix</h2>
        <h3 style="color: #E1E1E1 !important;" data-aos="zoom-in">Un prix adapté à vos demandes</h3>
    </div>

    <div class="row itemsContainer animatable fadeInUp" style="width: 100% !important;">
        <div class="priceDiv floating shadowPriceDiv">
            <div class="textPriceDiv flexboxVerticalAlign">
                <hr class="hrServices" style="margin-top: 20px;">
                <h3 style="color: black !important;text-transform: uppercase !important; margin-bottom: 0px !important;" class="customFont">
                    Site <br> vitrine
                </h3>
                <hr class="hrServices">
            </div>  
            <div class="descriptionPriceDiv">
                <ul class="flexboxVerticalAlign priceList">
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Réalisé avec Wordpress ou sur mesure</a> 
                        </div>             
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Charte graphique classique en fonction du client</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Hébergement classique possible</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Formulaire de contact</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Site lisible sur tous les appareils (responsive)</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Liens réseaux sociaux</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Toute demande pour un site vitrine classique </a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Mise en ligne du site </a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Modifications mineures possibles </a>
                        </div>
                    </li>
                </ul>
                <div class="flexboxHorizontalAlign" style="height: 10%;">            
                    <div class="flexboxVerticalAlign">
                        <div class="finalPriceDiv customSize4 hvr-buzz" style="cursor: pointer;"> À partir de</div>
                        <div class="finalPriceDiv customSize3 hvr-buzz" style="cursor: pointer;"> 400 €</div>
                    </div> 
                    <div class="flexboxVerticalAlign medalDiv">
                        <img src="../../assets/img/medal.png" class="medal hvr-grow">
                    </div>
                </div>
            </div>
        </div>
        <div class="priceDiv floating shadowPriceDiv">
            <div class="textPriceDiv flexboxVerticalAlign">
                <hr class="hrServices" style="margin-top: 20px;">
                <h3 style="color: black !important;text-transform: uppercase !important; margin-bottom: 0px !important;" class="customFont">
                    Site <br> personnalisé
                </h3>
                <hr class="hrServices">
            </div>
            <div class="descriptionPriceDiv">
                <ul class="flexboxVerticalAlign priceList">
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Réalisé sur mesure</a> 
                        </div>             
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Charte graphique classique en fonction du client</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Hébergement sur mesure possible</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Formulaire de contact</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Site lisible sur tous les appareils (responsive)</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Liens réseaux sociaux</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Toute demande pour un site vitrine ( dans la limite du possible)</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Mise en ligne du site </a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Interface administration </a>
                        </div>
                    </li>
                </ul>
                <div class="flexboxHorizontalAlign" style="height: 10%;">            
                    <div class="flexboxVerticalAlign">
                        <div class="finalPriceDiv customSize4 hvr-buzz" style="cursor: pointer;"> À partir de</div>
                        <div class="finalPriceDiv customSize3 hvr-buzz" style="cursor: pointer;"> 800 €</div>
                    </div> 
                    <div class="flexboxVerticalAlign medalDiv">
                        <img src="../../assets/img/medal.png" class="medal hvr-grow">
                    </div>
                </div>
            </div>
        </div>
        <div class="priceDiv floating shadowPriceDiv">
            <div class="textPriceDiv flexboxVerticalAlign">
                <hr class="hrServices" style="margin-top: 20px;">
                <h3 style="color: black !important;text-transform: uppercase !important; margin-bottom: 0px !important;" class="customFont">
                    Site <br> E-Commerce
                </h3>
                <hr class="hrServices">
            </div>
            <div class="descriptionPriceDiv">
                <ul class="flexboxVerticalAlign priceList">
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Réalisé sur mesure</a> 
                        </div>             
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Charte graphique classique en fonction du client</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Hébergement sur mesure</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Formulaire de contact</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Site lisible sur tous les appareils (responsive)</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Liens réseaux sociaux</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Toute demande pour un site de E commerce ( dans la limite du possible)</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a> Mise en ligne du site </a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Interface administration</a>
                        </div>
                    </li>
                </ul>
                <div class="flexboxHorizontalAlign" style="height: 10%;">            
                    <div class="flexboxVerticalAlign">
                        <div class="finalPriceDiv customSize4 hvr-buzz" style="cursor: pointer;"> À partir de</div>
                        <div class="finalPriceDiv customSize3 hvr-buzz" style="cursor: pointer;"> 1800 €</div>
                    </div> 
                    <div class="flexboxVerticalAlign medalDiv">
                        <img src="../../assets/img/medal.png" class="medal hvr-grow">
                    </div>
                </div>
            </div>
        </div>
        <div class="priceDiv floating shadowPriceDiv">
            <div class="textPriceDiv flexboxVerticalAlign">
                <hr class="hrServices" style="margin-top: 20px;">
                <h3 style="color: black !important;text-transform: uppercase !important; margin-bottom: 0px !important;" class="customFont">
                    Maintenance
                </h3>
                <hr class="hrServices">
            </div>
            <div class="descriptionPriceDiv" style="position: relative;">
                <ul class="flexboxVerticalAlign priceList">
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Mises à jour des Frameworks utilisés</a> 
                        </div>             
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Garantie de fonctionnement </a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Sauvegardes de la base de données régulière</a>
                        </div>
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" style="background-color: #F7F7F7;" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Possibilité d'effectuer des modifications du site</a>
                        </div>
                    </li>
                </ul>
                <hr class="hrServices" data-aos="zoom-in">
                <ul class="flexboxVerticalAlign priceList" style="padding-top: 0px !important; margin-top: 10px !important;">
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Optimisation des performances</a> 
                        </div>             
                    </li>
                    <li class="customSize4 flexboxHorizontalAlignStart" data-aos="zoom-in">
                        <img src="../../assets/img/validate.png" class="validate hvr-grow">
                        <div class="flexboxVerticalAlign priceTextContainer">  
                            <a>Migration possible</a>
                        </div>
                    </li>
                </ul>
                <div class="flexboxVerticalAlign hvr-buzz" style="height: 10%; margin-top: 200px;">
                    <div class="finalPriceDiv customSize4 hvr-buzz" style="cursor: pointer;"> À partir de</div>
                    <div class="flexboxHorizontalAlign hvr-buzz" style="cursor: pointer;">
                        <div class="finalPriceDiv customSize3"> 450 € </div>
                        <div class="finalPriceDiv customSize4" style="margin-left: 15px;"> / an </div>
                    </div>
                </div>
                <div class="flexboxVerticalAlign medalDiv">
                    <img src="../../assets/img/medal.png" class="medal hvr-grow">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="flexboxVerticalAlign" id="about" data-navlink="about">
  <div id="particles-js2"></div>
    <div class="sectionTitleDiv flexboxVerticalAlign">
        <h2 data-aos="zoom-in">À propos</h2>
        <h3 data-aos="zoom-in">Mon parcours et mon expérience</h3>
    </div>

    <hr class="hrAbout">
    <div class="row flexboxHorizontalAlign" style="margin-left: 5%; margin-right: 5%; margin-top: 50px;">
        <div class="col-lg flexboxVerticalAlign">

            <h3 data-aos="zoom-in" style="margin-bottom: 60px; margin-top: 30px; text-transform: none !important;">Mon parcours professionnel</h3>
            <ul class="professionalCareer customFont2">
                <li class="flexboxVerticalAlign professionalCareerDiv" data-aos="fade-right">
                    <div class="customSize2" style="width: 120px;">
                        2013-2016
                    </div>
                    <div class="flexboxVerticalAlign">
                        <h4 style="text-transform: none !important;">Lycée du Sacré-Coeur Aix-en-Provence</h4>
                        <div class="flexboxHorizontalAlign">
                            <img src="../../assets/img/certificat.png" class="certificate hvr-grow">
                            <div class="flexboxVerticalAlign">
                                <div class="customFont2" style="max-width: 405px;">• Baccalauréat Scientifique Spécialité physique</div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="flexboxVerticalAlign professionalCareerDiv" data-aos="fade-right">
                    <div class="customSize2" style="width: 140px;">
                        2016-2018
                    </div>
                    <div class="flexboxVerticalAlign">
                        <h4 style="text-transform: none !important;">Lycée Vauvenargues Aix-en-Provence</h4>
                        <div class="flexboxHorizontalAlign">
                            <img src="../../assets/img/certificat.png" class="certificate hvr-grow">
                            <div class="customFont2" style="max-width: 405px;">• BTS Systèmes Numériques Options Informatique et Réseaux
                            </div>
                        </div>
                    </div>
                </li>
                <li class="flexboxVerticalAlign professionalCareerDiv" data-aos="fade-right">
                    <div class="customSize2" style="width: 120px;">
                        2018-2019
                    </div>
                    <div class="flexboxVerticalAlign">
                        <h4 style="text-transform: none !important;">École privée d’informatique Ynov Aix-en-Provence</h4>
                        <div class="flexboxHorizontalAlign">
                            <img src="../../assets/img/certificat.png" class="certificate hvr-grow">
                            <div class="customFont2" style="max-width: 405px;">• Bachelor Développement Web et applications
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

        </div>
        <div class="col-lg flexboxSpaceBetweenColumnAlign">

            <h3 data-aos="zoom-in" style="margin-bottom: 80px; margin-top: 50px; text-transform: none !important;">À propos de moi</h3>
            <div class="flexboxVerticalAlign">
                <div style="margin-top: 10px; margin-bottom: 10px;" class="customParagraph" data-aos="fade-up">
                    <p style="text-transform: none !important;">
                        J'ai commencé mes études supérieures par un BTS traitant les métiers  du numérique puis petit à petit 
                        la possibilité de pouvoir créer quelque chose à la fois graphiquement et 
                        traitant une logique dans la programmation m'a donné envie de poursuivre dans un Bachelor spécialisé pour le développement web.<br>
                        J'ai ensuite appris par moi même pour créer des sites à mon image.
                    </p>
                </div>
                <div data-aos="zoom-in">
                    <img src="../../assets/img/growth.png" class="hvr-grow" style="width: 120px; height: 120px; margin-top: 25px; margin-bottom: 25px; cursor: pointer;">
                </div>
                <div style="margin-top: 10px; margin-bottom: 60px;" class="customParagraph" data-aos="fade-up">
                    <p style="text-transform: none !important;">
                        Mon but est de répondre le plus possible aux attentes de mes clients pour leur proposer un rendu fidèle 
                        à leurs demande aussi bien sur le visuel que dans les fonctionnalités 
                        et la productivité.
                    </p>
                </div>

            </div>

            <div class="flexboxVerticalAlign stainDiv">
                <img src="../../assets/img/inkStain.png" class="stain hvr-grow">
            </div>

        </div>
    </div>

    <hr class="hrAbout">


    <div class="row flexboxHorizontalAlign" style="margin-bottom: 0px !important; margin-right: 0px !important; margin-left: 0px !important;">
        <div data-aos="fade-up">
            <div class="flexboxVerticalAlign divIcon backgroundcolorTransparent hvr-grow" id="divIcon1">
                <img src="../../assets/img/structured.png" class="imageIcon">
                <h4 style="text-transform: none !important;" class="customSize3 textIcon">Structuré</h4>
            </div>
        </div>
        <div data-aos="fade-up">
            <div class="flexboxVerticalAlign divIcon backgroundcolorTransparent hvr-grow" id="divIcon2">
                <img src="../../assets/img/flexible.png" class="imageIcon">
                <h4 style="text-transform: none !important;" class="customSize3 textIcon">Flexible</h4>
            </div>
        </div>
        <div data-aos="fade-up">
            <div class="flexboxVerticalAlign divIcon backgroundcolorTransparent hvr-grow" id="divIcon3">
                <img src="../../assets/img/energetic.png" class="imageIcon">
                <h4 style="text-transform: none !important;" class="customSize3 textIcon">Énergétique</h4>
            </div>
        </div>
        <div data-aos="fade-up">
            <div class="flexboxVerticalAlign divIcon backgroundcolorTransparent hvr-grow" id="divIcon4">
                <img src="../../assets/img/efficient.png" class="imageIcon">
                <h4 style="text-transform: none !important;" class="customSize3 textIcon">Efficace</h4>
            </div>
        </div>
    </div>

    <div class="container flexboxVerticalAlign" style="position: relative;">

        <img src="../../assets/img/cloud2.png" class="cloud" style="top: 30%; left: 0%; ">
        <img src="../../assets/img/cloud2.png" class="cloud" style="top: 30%; left: 5%;">
        <img src="../../assets/img/cloud.png" class="cloud" style="top: 30%; left: 10%;">

        <img src="../../assets/img/cloud2.png" class="cloud" style="top: 30%; right: 0%;">
        <img src="../../assets/img/cloud2.png" class="cloud" style="top: 30%; right: 5%;">
        <img src="../../assets/img/cloud.png" class="cloud" style="top: 30%; right: 10%;">

        <div class="flexboxVerticalAlign" id="figureContainer" style="min-height: 500px; width: 100%; margin-bottom: 70px;">

            <p class="customSize3" id="paragraphFigure" style="position: absolute; text-transform: none; bottom: 0;">
                Prends le risque ou perds ta chance !
            </p>

            <figure class="figure">
                <p class="customSize3" id="clickMeFigure" style="position: absolute; bottom: -50px; left: 150px !important;text-transform: none;">
                    Click me !
                </p>
                <div class="c cOriginalSize"></div>
                <div class="c2 c2OriginalSize"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div>
                <div class="c3 c3OriginalSize"></div>
                <div class="c4 c4OriginalSize"></div>
                <div class="c5 c5OriginalSize"></div>
                <div class="c6 c6OriginalSize"></div>
            </figure>
        </div>

    </div>

</section>

<div id="parallaxBackground2">
    <div style="height:300px;">&nbsp;</div>
</div>

<section id="contact" data-navlink="contact" class="flexboxVerticalAlign">

    <div class="sectionTitleDiv flexboxVerticalAlign">
        <h2 style="color: white !important;" data-aos="zoom-in">Contact</h2>
        <h3 style="color: #E1E1E1 !important;" data-aos="zoom-in">Prendre contact avec moi</h3>
    </div>

    <div style="margin-bottom: 200px; position: relative;">
        <div class="row" style="position: relative; margin-left: 0px !important; margin-right: 0px !important;">
            <div class="col colContact">
                <div class="flexboxVerticalAlign">
                <div class="row justify-content-center">
                    <div class="col">
                        <div class="card" data-aos="fade-right">
                            <div class="card-body py-md-4">
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <input type="text" formControlName="name" class="form-control" id="name" placeholder="Nom et Prénom" [ngClass]="{ 'is-invalid': submitted && !f.name.valid}">
                                        <div *ngIf="!f.name.valid" class="invalid-feedback">
                                            <div *ngIf="!f.name.valid" style="color: white;">Saisissez votre nom et prénom</div>
                                        </div>
                                    </div>                              
                                    <div class="form-group">
                                        <input type="email" formControlName="email" class="form-control" id="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors}">
                                        <div *ngIf="!f.email.valid" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required" style="color: white;">Saisissez votre email</div>
                                            <div *ngIf="f.email.errors.email" style="color: white;">L'Email doit être valide</div>
                                        </div>
                                    </div>                                                                                           
                                    <div class="form-group">
                                        <input formControlName="phone" class="form-control" id="phone" placeholder="Téléphone" [ngClass]="{ 'is-invalid': submitted && !f.phone.valid}">
                                        <div *ngIf="!f.phone.valid" class="invalid-feedback">
                                            <div  *ngIf="!f.phone.valid" style="color: white;">Saisissez votre téléphone</div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <textarea class="form-control" formControlName="message" id="message" rows="5" placeholder="Message" style="padding: 10px !important;" [ngClass]="{ 'is-invalid': submitted && !f.message.valid}"></textarea>
                                        <div *ngIf="!f.message.valid" class="invalid-feedback">
                                            <div *ngIf="!f.message.valid" style="color: white;">Saisissez un message</div>
                                        </div>
                                    </div>
                                    <div class="flexboxHorizontalAlign">
                                        <button class="btn btn-primary contactBtn customSize4" style="margin: 5px;">Envoyer</button>
                                        <button class="btn btn-primary contactBtn customSize4" type="reset" (click)="onReset()" style="margin: 5px;">Effacer</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="col colContact">
                <ul class="flexboxVerticalAlign contactList">
                    <li class="flexboxHorizontalAlignStart liContact" data-aos="fade-up">                 
                        <div class="flexboxVerticalAlign contactTextContainer">  
                            <div class="flexboxHorizontalAlign" style="margin-bottom: 5px;"> 
                                <img src="../../assets/img/mail.png" class="iconContact hvr-grow">
                                <a class="customSize3" style="color: white !important; text-transform: none !important; width: 100px;">E-mail</a> 
                            </div>
                            <a class="customSize4" style="color: white !important;">masvalentin@hotmail.fr</a>
                        </div>             
                    </li>
                    <li class="flexboxHorizontalAlignStart liContact" data-aos="fade-up">
                        <div class="flexboxVerticalAlign contactTextContainer">  
                            <div class="flexboxHorizontalAlign" style="margin-bottom: 5px;">  
                                <img src="../../assets/img/phone.png" class="iconContact hvr-grow">
                                <a class="customSize3" style="color: white !important; text-transform: none !important; width: 100px;">Téléphone</a>
                            </div>
                            <a class="customSize4" style="color: white !important; margin-left: 30px;">06.08.48.98.33</a>
                        </div>
                    </li>
                    <li class="flexboxHorizontalAlignStart liContact" data-aos="fade-up">              
                        <div class="flexboxVerticalAlign contactTextContainer">  
                            <div class="flexboxHorizontalAlign" style="margin-bottom: 5px;">  
                                <img src="../../assets/img/location.png" class="iconContact hvr-grow" style="width: 40px; height: 40px; margin-right: 15px !important;">
                                <a class="customSize3" style="color: white !important; text-transform: none !important; width: 100px;">Adresse</a>
                            </div>
                            <a class="customSize4" style="color: white !important;">7A Traverse du Vallon <br>13220, Châteauneuf-les-martigues</a>
                        </div>
                    </li>
                    <li class="flexboxHorizontalAlignStart liContact" data-aos="fade-up">
                        <div class="flexboxVerticalAlign contactTextContainer">  
                            <div class="flexboxHorizontalAlign" style="margin-bottom: 5px;">  
                                <img src="../../assets/img/clock.png" class="iconContact hvr-grow">
                                <a class="customSize3" style="color: white !important; text-transform: none !important;">Horaires</a>
                            </div>
                            <a class="customSize4" style="color: white !important;">Disponibilité variable mais généralement: <br style="margin-top: 3px;"> Lundi au samedi 10h à 19h</a>
                        </div>
                    </li>              
                </ul>
            </div>
            <div class="col flexboxVerticalAlign colContact">
                <div id="map" data-aos="fade-left">
                    <iframe frameborder="0" style="border:0; width: 100%; height: 100%;" src="https://www.google.com/maps/embed/v1/place?q=place_id:EjxUcmF2ZXJzZSBkdSBWYWxsb24sIDEzMjIwIENow6J0ZWF1bmV1Zi1sZXMtTWFydGlndWVzLCBGcmFuY2UiLiosChQKEgkDRXxEP-fJEhGEsBP9W56Z0RIUChIJXQqlUDTnyRIR8AOX_aUZCAQ&key=AIzaSyCWKvXPO7jpgZfz7MIVALk4Wt92lzBCacc"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>

<a id="scrollToTopArrow">
    <img src="../../assets/img/arrowUp.png" class="hvr-pulse-grow" style="width: 100%; height: 100%;">
</a>

<app-footer></app-footer>