import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-div',
  templateUrl: './project-div.component.html',
  styleUrls: ['./project-div.component.css']
})
export class ProjectDivComponent implements OnInit {

  constructor() { }


  @Input() src: any;
  @Input() firstText: any;
  @Input() secondText: any;
  @Input() description: any;
  @Input() URL: any;

  ngOnInit(): void {

  }

}
