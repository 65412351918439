import { Component, OnInit, ElementRef } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.css']
})
export class MainCarouselComponent implements OnInit {

  constructor(private element: ElementRef) { }




  ngOnInit(): void {

    $(".burger").click(function() {
      $(this).toggleClass('active');
      $("ul.menu li").slideToggle('fast');
  })

  $(window).resize(function() {
      if ($(window).width() > 650) {
          $('ul.menu li').removeAttr('style');
      }
  })

  }

  ngAfterViewInit(): void {
    var revapi;
        $(document).ready(function() {
            revapi = $("#rev_slider").revolution({
                sliderType: "standard",
                sliderLayout: "auto",
                fullScreenOffsetContainer: ".header",
                fullScreenOffset: "50px",
                delay: 4000,
                navigation: {
                    arrows: {
                        enable: true
                    }
                },
                responsiveLevels: [1240, 1024, 778, 480],
                visibilityLevels: [1240, 1024, 778, 480],
                gridwidth: [1240, 1024, 778, 480],
                gridheight: [620, 520, 580, 480],

            });
        }); /*ready*/

  }

}
