import { Component, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import {MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;
declare var AOS: any;
declare var particlesJS: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder, 
    private http: HttpClient,
    private matDialog: MatDialog,
    private dom: DomSanitizer
    ) { }

    
  registerForm: FormGroup;
  submitted = false;
  

  private api = "http://45.66.223.46:8090/sendContactForm2"

  // projects list
  projectDivList = [{
                    id:"0",
                    imageSRC:"../assets/img/floramaria.jpg",
                    firstText:" Floramaria.fr",
                    secondText:"site vitrine personnalisé",
                    description:" Angular <br>  NodeJS <br>  Express <br>  JQuerry <br>  Bootstrap",
                    URL:"http://floramaria.fr/"
                     },{
                    id:"1",
                    imageSRC:"../assets/img/presentation.jpg",
                    firstText:"MasValentin.fr",
                    secondText:"site vitrine personnalisé",
                    description:" Angular <br>  JQuerry <br>  Bootstrap <br>  librairies JS diverses pour animations",
                    URL:"http://localhost/"
                     }
  ];

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
          return;
      }

        // display form values on success
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value, null, 4));

        this.http.post(this.api, this.registerForm.value).subscribe(
          data => {
            alert(data);
          },
          err => {
            console.log(err);
            alert("erreur:" + err);
          }
      );

    }

  onReset() {
      this.submitted = false;
      this.registerForm.reset();
  }

  get f() { return this.registerForm.controls; }

  public ngOnInit()
  {

    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      message: ['', Validators.required]
  });


    //init AOS
    AOS.init();

    // start watter ripple
    $('.rippleDiv').ripples({
      resolution: 512,
      dropRadius: 1,
      perturbance: 0.03,
    });

  // add active class on scroll for navbar


    var addClassOnScroll = function () {
      var windowTop = $(window).scrollTop();
      $('section[id]').each(function (index, elem) {
          var offsetTop = $(elem).offset().top;
          var outerHeight = $(this).outerHeight(true);

          if( windowTop > (offsetTop - 146) && windowTop < ( offsetTop + outerHeight)) {
              var elemId = $(elem).attr('id');
              $("nav ul li a.active").removeClass('active');
              $("nav ul li a[href='#" + elemId + "']").addClass('active');
              $(".navAsideContent a").children().removeClass('activeSideNavBar');
              $(".navAsideContent a").children().children().removeClass('fadeIn').addClass("fadeOut");
              $(".navAsideContent a[href='#" + elemId + "']").children().addClass('activeSideNavBar');
              $(".navAsideContent a[href='#" + elemId + "']").children().children().addClass('fadeIn').removeClass("fadeOut");
          }
      });
    };

    $(function () {
      $(window).on('scroll', function () {
          addClassOnScroll();
      });
    });

    //start parallax video in the background

    let audioPlayer = <HTMLVideoElement>document.getElementById('video');
    audioPlayer.muted = true;


    // scroll top on reload
    $(window).on('beforeunload', function() {
      $(window).scrollTop(0);
  });


  //fix the navbar to the top on scroll

    var fixedToTopDisabled = false;

        $(document).ready(function() {
          var docEl = $(document),
              headerEl = $('header'),
              menu = $('.menu'),
              headerWrapEl = $('.wrapHead'),
              navEl = $('nav'),
              brand = $('#brand'),
              liMenu = $('.liMenu'),
              brandImg = $('#brandImg'),
              linkScroll = $('.scroll'),
              navbarTitles = $('.navbarTitles'),
              blurredBackground = $('#blurredBackground');



          docEl.on('scroll', function() {
              if (docEl.scrollTop() > 60) {
                if(fixedToTopDisabled == false){
                  headerEl.addClass('fixed-to-top');
                  headerWrapEl.addClass('fixed-to-top');
                  menu.addClass('fixed-to-top');
                  liMenu.addClass('fixed-to-top');
                  navEl.addClass('fixed-to-top');
                  brandImg.addClass('fixed-to-top');
                  brand.addClass('fixed-to-top');
                  navbarTitles.addClass('fixed-to-top');
                  linkScroll.addClass('fixed-to-top');
                  blurredBackground.addClass('fixed-to-top');
                  $("#scrollToTopArrow").addClass('fadeIn');
                  $("#scrollToTopArrow").removeClass('fadeOut');
                }
              } else {
                  headerEl.removeClass('fixed-to-top');
                  headerWrapEl.removeClass('fixed-to-top');
                  navEl.removeClass('fixed-to-top');
                  menu.removeClass('fixed-to-top');
                  liMenu.removeClass('fixed-to-top');
                  brandImg.removeClass('fixed-to-top');
                  navbarTitles.removeClass('fixed-to-top');
                  brand.removeClass('fixed-to-top');
                  linkScroll.removeClass('fixed-to-top');
                  blurredBackground.removeClass('fixed-to-top');
                  $("#scrollToTopArrow").addClass('fadeOut');
                  $("#scrollToTopArrow").removeClass('fadeIn');
              }
          });


          var $window = $(window);
          var windowsize = $window.width();
          var $anchor = $(this);

          if (windowsize > 1050) {
              docEl.on('scroll', function() {
                if (docEl.scrollTop() > 320) {
                    $(".navAside").addClass('fadeIn');
                    $(".navAside").removeClass('fadeOut');

                } else {
                    $(".navAside").addClass('fadeOut');
                    $(".navAside").removeClass('fadeIn');
                }
            });
          }
      });


      // Smoth scroll on navbar link click
      $('.smoth-scroll').on('click', function() {

        var $window = $(window);
        var windowsize = $window.width();
        var $anchor = $(this);

        if (windowsize < 1051) {
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 110)
        }, 1250, 'easeInOutExpo');
            $(".burger").click();
          }
          else{
            fixedToTopDisabled = true;
            setTimeout(function(){
              fixedToTopDisabled = false;
            }, 300);


            $('html, body').stop().animate({
              scrollTop: ($($anchor.attr('href')).offset().top - 131)
          }, 1250, 'easeInOutExpo',);
          }
    });


    // Smoth scroll on arrowDown click
    $('.smoth-scroll-arrow').on('click', function() {

      var $window = $(window);
      var windowsize = $window.width();
      var $anchor = $(this);

      if (windowsize < 1051) {
      $('html, body').stop().animate({
          scrollTop: ($($anchor.attr('href')).offset().top - 86)
      }, 1250, 'easeInOutExpo');
        }
        else{
          fixedToTopDisabled = true;
          setTimeout(function(){
            fixedToTopDisabled = false;
          }, 300);
          $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - 131)
        }, 1250, 'easeInOutExpo');
        }
    });


    // smoth scroll to top on arrowUp click
    $('#scrollToTopArrow').on('click', function() {

      var $window = $(window);
      var windowsize = $window.width();
      var $anchor = $(this);

      $('html, body').stop().animate({
          scrollTop: ($($('body')).offset().top)
      }, 1250, 'easeInOutExpo');

  });

// Particles.js

particlesJS.load('particles-js', 'assets/js/particles.js-master/particles.json');

particlesJS.load('particles-js2', 'assets/js/particles.js-master/particles2.json');

// Figure

$(".figure").addClass("figureNotClicked");
$("i").addClass("iOriginalSize");
$('.c').toggleClass('changed2');
$('.c3').toggleClass('changed2');
$("#paragraphFigure").hide();
$(".cloud").fadeOut(0);

function Clicked() {

    $(".figure").addClass("figureClicked").removeClass("figureNotClicked");
    $("#clickMeFigure").fadeOut(1000);

    setTimeout( function () {
    $("i").addClass("iCustomSize").removeClass("iOriginalSize");
    setTimeout(function(){ 
      $(".c").addClass("cCustomSize").removeClass("cOriginalSize");
      $('.c').removeClass('changed2').toggleClass('changed');
      setTimeout(function(){  
        $(".c3").addClass("c3CustomSize").removeClass("c3OriginalSize");
        $('.c3').removeClass('changed2').toggleClass('changed');
        setTimeout(function(){      
          $("#paragraphFigure").slideDown(1000);
          $(".cloud").fadeIn(1000);
        }, 400);
      }, 400);
    }, 400);
  }, 600);
  $(this).one("click", notClicked);
}

function notClicked() {

  $("#paragraphFigure").slideUp(1000);
  $(".cloud").fadeOut(1000);

  setTimeout( function () {
    $(".figure").addClass("figureNotClicked").removeClass("figureClicked");
    $("i").addClass("iOriginalSize").removeClass("iCustomSize");
    setTimeout(function(){ 
      $(".c").addClass("cOriginalSize").removeClass("cCustomSize");
      $('.c').removeClass('changed').toggleClass('changed2')
      setTimeout(function(){    
        $(".c3").addClass("c3OriginalSize").removeClass("c3CustomSize");
        $('.c3').removeClass('changed').toggleClass('changed2');
        $("#clickMeFigure").fadeIn(1000);
      }, 400);
    }, 400);
  }, 600);
  $(this).one("click", Clicked);
}

$(".figure").one("click", Clicked);


// Textillate animation

$('.tlt').appear(function() {
  $('.tlt').textillate({  minDisplayTime: 2000, in: { effect: 'bounceIn' }});
});

$(window).scroll(function(e) {

  var $window = $(window);
  var windowsize = $window.width();
  if (windowsize > 1650) {
    parallax();
  }
})

// all parallax effects

function parallax() {
  var scroll = $(window).scrollTop();
  var screenHeight = $(window).height();


  $('.parallax').each(function() {
    var offset = $(this).offset().top;
    var distanceFromBottom = offset - scroll - screenHeight

    if (offset > screenHeight && offset) {
      $(this).css('background-position', 'center ' + (( distanceFromBottom  ) * 0.5) +'px');
    } else {
      $(this).css('background-position', 'center ' + (( -scroll ) * 0.5) + 'px');
    }
  })

}

$(document).ready(function() {
  //parallax scroll
  $(window).on("load scroll", function() {
    var parallaxElement = $(".parallaxScroll"),
      parallaxQuantity = parallaxElement.length;
      for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
          windowTop = $(window).scrollTop(),
          elementTop = currentElement.offset().top,
          elementHeight = currentElement.height(),
          viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
          scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: "translate3d(0," + scrolled * -0.60 + "px, 0)"
        });
      }
  });
});


$(document).ready(function() {
  //parallax scroll
  $(window).on("load scroll", function() {
    var parallaxElement = $(".parallaxScroll2"),
      parallaxQuantity = parallaxElement.length;
      for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
          windowTop = $(window).scrollTop(),
          elementTop = currentElement.offset().top,
          elementHeight = currentElement.height(),
          viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
          scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: "translate3d(0," + scrolled * -0.50 + "px, 0)"
        });
      }
  });
});

$(document).ready(function() {
  //parallax scroll
  $(window).on("load scroll", function() {
    var parallaxElement = $(".parallaxScroll3"),
      parallaxQuantity = parallaxElement.length;
      for (var i = 0; i < parallaxQuantity; i++) {
        var currentElement = parallaxElement.eq(i),
          windowTop = $(window).scrollTop(),
          elementTop = currentElement.offset().top,
          elementHeight = currentElement.height(),
          viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
          scrolled = windowTop - elementTop + viewPortHeight;
        currentElement.css({
          transform: "translate3d(0," + scrolled * -0.25 + "px, 0)"
        });
      }
  });
});

//counter

$('.count').each(function () {
  $(this).prop('Counter',0).animate({
      Counter: $(this).text()
  }, {
      duration: 6000,
      easing: 'swing',
      step: function (now) {
          $(this).text(Math.ceil(now));
      }
  });
});

$( ".divIcon" ).mouseenter(function() {
  $( this ).removeClass("backgroundcolorTransparent").addClass("backgroundcolorBlack");
  $( this ).children().addClass("colorTransparent").removeClass("colorBlack");
  var id = $( this ).attr('id');
  switch(id) {
    case "divIcon1":
      $( this ).children().attr("src", "../../assets/img/structuredWhite.png");
      break;
    case "divIcon2":
      $( this ).children().attr("src", "../../assets/img/flexibleWhite.png");
      break;
    case "divIcon3":
      $( this ).children().attr("src", "../../assets/img/energeticWhite.png");
    break;
    case "divIcon4":
      $( this ).children().attr("src", "../../assets/img/efficientWhite.png");
      break;
  }

});

$( ".divIcon" ).mouseleave(function() {
  $( this ).addClass("backgroundcolorTransparent").removeClass("backgroundcolorBlack");
  $( this ).children().addClass("colorBlack").removeClass("colorTransparent");
  var id = $( this ).attr('id');
  switch(id) {
    case "divIcon1":
      $( this ).children().attr("src", "../../assets/img/structured.png");
      break;
    case "divIcon2":
      $( this ).children().attr("src", "../../assets/img/flexible.png");
      break;
    case "divIcon3":
      $( this ).children().attr("src", "../../assets/img/energetic.png");
    break;
    case "divIcon4":
      $( this ).children().attr("src", "../../assets/img/efficient.png");
      break;
  }
});


// Skills Bars
var skills = {
  ht: 90,
  jq: 90,
  sk: 70,
  ph: 40,
  il: 60,
  in: 80,
  pl: 25
};

$.each(skills, function(key, value) {
  var skillbar = $('.' + key);
  
  skillbar.animate(
  {
    width: value + "%"
  },
  3000,
  function() {
    $(".speech-bubble").fadeIn();
  }
  );
});


  }
}
