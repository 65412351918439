<header>
    <div id="blurredBackground"></div>
    <div id="headerExtensionMobileDevices"></div>
    <div class="wrapHead">
        <div id="brand">
            <img src="../../assets/img/logo.png" id="brandImg" class="hvr-grow">
        </div>
        <nav>
            <a href="#" class="burger">
                <div id="burgerContent"></div>
            </a>

            <ul class="menu">
                <li class="hvr-grow services">
                    <a href="#services" class="smoth-scroll">
                        <h4 class="navbarTitles" href="#services" data-aos="fade-down">services</h4>
                    </a>
                </li>
                <li class="hvr-grow knowledges">
                    <a href="#knowledges" class="smoth-scroll">
                        <h4 class="navbarTitles" href="#knowledges" data-aos="fade-down">compétences</h4>
                    </a>
                </li>
                <li class="hvr-grow projects">
                    <a href="#projects" class="smoth-scroll">
                        <h4 class="navbarTitles" href="#projects" data-aos="fade-down">projets</h4>
                    </a>
                </li>
                <li class="hvr-grow pricing">
                    <a href="#pricing" class="smoth-scroll">
                        <h4 class="navbarTitles" href="#pricing" data-aos="fade-down">tarifs</h4>
                    </a>
                </li>
                <li class="hvr-grow about">
                    <a href="#about" class="smoth-scroll">
                        <h4 class="navbarTitles" href="#about" data-aos="fade-down">à propos</h4>
                    </a>
                </li>
                <li class="hvr-grow contact">
                    <a href="#contact" class="smoth-scroll">
                        <h4 class="navbarTitles" href="#contact" data-aos="fade-down">contact</h4>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</header>