<div class="projectDiv hvr-float">
    <a href="{{this.URL}}" style="text-decoration: none !important;">
        <div data-aos="zoom-in">

            <img class="imageProjectDiv shadowProjectDiv" src="{{this.src}}" data-aos="fade-down">

            <div class="textProjectDiv flewboxVerticalAlign shadowProjectDiv" data-aos="flip-up">
                <h3 style="color: white !important; margin-left: 7px !important; padding-top: 7px !important;" class="customFont">
                    {{this.firstText}}
                </h3>
                <h4 style="color: #E1E1E1 !important; margin-left: 7px !important; padding-bottom: 13px !important; margin-bottom: 5px !important;" class="customFont">
                    {{this.secondText}}
                </h4>
            </div>
            <div class="descriptionProjectDiv flewboxVerticalAlign shadowProjectDiv" data-aos="flip-up">
                <h4 style="color: #1c1c1c !important; margin-left: 7px !important; padding-bottom: 13px !important; margin-bottom: 5px !important; text-transform: none !important;" class="customFont" [innerHTML]="this.description">

                </h4>
            </div>
        </div>
    </a>
</div>
