<!-- START REVOLUTION SLIDER 5.0 -->
<div class="rev_slider_wrapper">
    <!-- START REVOLUTION SLIDER 5.0 auto mode -->
    <div id="rev_slider" class="rev_slider" data-version="5.0">

        <a href="#services" class="smoth-scroll-arrow scrollArrow">
            <img src="../../assets/img/arrowDown.png" class="hvr-pulse-grow topArrow">
        </a>
        <ul>
            <!-- SLIDE-1  -->

            <li data-transition="fade" class="layer1">
                <!-- MAIN IMAGE -->

                <img src="../../assets/img/carousel1.jpg" class="rev-slidebg" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-kenburns="on" data-duration="30000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="200" data-offsetstart="0 0"
                    data-offsetend="0 0" data-rotatestart="0" data-rotateend="0">


                <div class="flewboxVerticalAlign" style="max-width: 90%;">

                    <!-- LAYER .1 -->
                    <div class="tp-caption tp-resizeme rs-parallaxlevel-0 customFont" id="slide-1-layer-2" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['-20','0','0','0']" data-fontsize="['60','60','60','50']"
                        data-lineheight="['82','82','82','72']" data-width="700" data-height="none" data-color="#fff" data-transform_in="x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0.01;s:3000;e:Power3.easeOut;" data-transform_out="s:1000;e:Power3.easeInOut;s:1000;e:Power3.easeInOut;"
                        data-mask_in="x:[100%];y:0;s:inherit;e:inherit;" data-start="1000" data-splitin="lines" data-splitout="none" data-responsive_offset="on" style="z-index: 100 !important; font-weight:500; letter-spacing:1px; text-transform:uppercase; font-family: 'Roboto', sans-serif; text-align:center;  white-space: nowrap; max-width: 90% !important;">
                        MAS<span class="text-greendark">  VALENTIN</span>
                    </div>


                    <!-- LAYER .2 -->
                    <div class="tp-caption tp-resizeme rs-parallaxlevel-0 customFont" id="slide-1-layer-3" data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['30','40','30','30']"
                        data-fontsize="['16','16','16','16']" data-lineheight="['18','18','18','18']" data-width="700" data-height="none" data-color="#fff" data-transform_idle="o:1;tO:0% 50%;" data-transform_in="x:-50px;rY:-90deg;opacity:0;s:2000;e:Back.easeOut;"
                        data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;" data-start="1000" data-splitin="lines" data-splitout="none" data-responsive_offset="on" data-elementdelay="0.05" style="z-index: 7; font-weight:600; letter-spacing:1px; text-transform:none; font-family: 'Roboto', sans-serif; text-align:center;  white-space: nowrap; margin-top: 150px;max-width: 90% !important;">
                        Créateur de sites webs.
                    </div>

                </div>
            </li>



            <!-- SLIDE-2  -->
            <li data-transition="fade">
                <!-- MAIN IMAGE -->

                <img src="../../assets/img/carousel2.jpg" class="rev-slidebg" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-kenburns="on" data-duration="30000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="200" data-offsetstart="0 0"
                    data-offsetend="0 0" data-rotatestart="0" data-rotateend="0">


                <!-- LAYER .1 -->
                <div class="tp-caption  tp-resizeme rs-parallaxlevel-0 customFont" id="slide-2-layer-1" data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['-90','-70','-70','-70']" data-fontsize="['52','52','42','42']"
                    data-lineheight="['62','62','42','42']" data-width="0" data-height="none" data-color="#fff" data-transform_in="x:500px;opacity:0;s:1500;e:Power4.easeInOut;" data-transform_out="y:[175%];s:1500;e:Power2.easeInOut;s:1500;e:Power2.easeInOut;"
                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 10;  font-weight:800; text-align:left; text-transform:uppercase; white-space: nowrap; margin-top: 150px;">
                    Création de <br/>
                    <div>Sites web</div>
                </div>



                <!-- LAYER .2 -->
                <div class="tp-caption tp-resizeme rs-parallaxlevel-0 customFont" id="slide-2-layer-2" data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['10','10','10','0']" data-fontsize="['32','32','32','32']"
                    data-lineheight="['32','32','32','32']" data-width="700" data-height="none" data-color="#fff" data-transform_idle="o:1;tO:0% 50%;" data-transform_in="x:-50px;rY:-90deg;opacity:0;s:2000;e:Back.easeOut;" data-transform_out="s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                    data-start="1000" data-splitin="lines" data-splitout="none" data-responsive_offset="on" data-elementdelay="0.05" style="z-index: 10; font-weight:600; text-transform:none; font-family: 'Roboto', sans-serif; text-align:left;  white-space: nowrap; margin-top: 150px;">
                    Meilleur choix<br/> pour votre projet web
                </div>

            </li>


            <!-- SLIDE-3  -->
            <li data-transition="fade">
                <!-- MAIN IMAGE -->

                <img src="../../assets/img/carousel3.jpg" class="rev-slidebg" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-kenburns="on" data-duration="30000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="200" data-offsetstart="0 0"
                    data-offsetend="0 0" data-rotatestart="0" data-rotateend="0">

                <!-- LAYER .1 -->
                <div class="tp-caption  tp-resizeme rs-parallaxlevel-0 customFont" id="slide-3-layer-1" data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['-70','-70','-70','-70']" data-fontsize="['52','52','42','42']"
                    data-lineheight="['62','62','42','42']" data-width="none" data-height="none" data-color="#fff" data-transform_in="x:500px;opacity:0;s:1500;e:Power4.easeInOut;" data-transform_out="y:[175%];s:1500;e:Power2.easeInOut;s:1500;e:Power2.easeInOut;"
                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 10;  font-weight:800; text-align:left; text-transform:uppercase; white-space: nowrap; margin-top: 150px;">
                    Création <br/> de <br/> Sites web


                </div>



                <!-- LAYER .2 -->
                <div class="tp-caption tp-resizeme rs-parallaxlevel-0 customFont" id="slide-3-layer-2" data-x="['left','left','left','left']" data-hoffset="['0','0','0','0']" data-y="['center','center','center','center']" data-voffset="['30','50','50','30']" data-fontsize="['21','21','21','21']"
                    data-lineheight="['32','32','32','32']" data-width="700" data-height="none" data-color="#fff" data-transform_in="x:-1000px;y:0px;opacity:0;s:1000;e:Power2.easeInOut;" data-transform_out="y:[175%];s:1500;e:Power2.easeInOut;s:1500;e:Power2.easeInOut;"
                    data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;" data-start="1000" data-splitin="none" data-splitout="none" data-responsive_offset="on" style="z-index: 10; font-weight:600; text-transform:none; font-family: 'Roboto', sans-serif; text-align:left;  white-space: nowrap; margin-top: 200px;">
                    Site vitrine, E-commerce <br/> ou autres
                </div>

            </li>



        </ul>
    </div>
    <!-- END REVOLUTION SLIDER -->
</div>
