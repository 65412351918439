<div style="position: relative;">
<div class="footerBackgroundCover"></div>
<footer class="page-footer font-small teal pt-4" style="background-color: #e8e8e8; position: relative; ">
    <div class="footerContainer">
        <div class="container-fluid text-center text-md-left">
            <div class="row">
                <div class="col-md-6 mt-md-0 mt-3 flewboxVerticalAlign" style="text-align: center !important;">
                    <h5 class="customSize3 font-weight-bold" style="margin-bottom: 25px;" data-aos="fade-up">DÉVELOPPEUR INFORMATIQUE INDÉPENDANT</h5>
                    <p data-aos="fade-up"> Développeur Web front & back-end & Webdesigner freelance, je suis à votre disposition pour répondre à tout type de projets de création de sites internet, 
                        de développement spécifique ou d'applications web.
                        Passionné par les technologies liées au Web, je mets mes compétences au service de vos besoins dans divers domaines.
                    </p>
                </div>
                <hr class="clearfix w-100 d-md-none pb-3">
                <div class="col-md-6 mb-md-0 mb-3 flewboxVerticalAlign" style="text-align: center !important;">
                    <h5 class="customSize3 font-weight-bold" style="margin-bottom: 25px;" data-aos="fade-up">coordonnées</h5>
                    <ul class="flewboxVerticalAlign contactList" style="padding-top: 0px !important;" data-aos="fade-up">
                        <li class="flewboxHorizontalAlignStart liContact2">  
                            <p>masvalentin@hotmail.fr</p>                   
                        </li>
                        <li class="flewboxHorizontalAlignStart liContact2">
                            <p>06.08.48.98.33</p>    
                        </li>
                        <li class="flewboxHorizontalAlignStart liContact2">    
                            <p>7A Traverse du Vallon <br>13220, Châteauneuf-les-martigues</p>    
                        </li>
                        <li class="flewboxHorizontalAlignStart liContact2">
                            <p>mas-valentin.fr</p>    
                        </li>              
                    </ul>
                </div>
            </div>
        </div>
        <div class="bottomContainer">
            <div class="customFont4 bottomFooterContainer">
                <a (click)="openDialog()" style="cursor: pointer; color: blue;">mentions légales</a>
                <div> SIRET : 88832904200012 </div>
            </div>
            <div class="footer-copyright text-center py-3" style="background-color: #e8e8e8 !important; z-index: 2;">
                <div class="customFont customSize tlt"> Créé par Valentin Mas</div>
            </div>
        </div>
    </div>
</footer>
</div>