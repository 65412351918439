import { Component, OnInit } from '@angular/core';
import {MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-infos-dialog',
  templateUrl: './infos-dialog.component.html',
  styleUrls: ['./infos-dialog.component.css']
})
export class InfosDialogComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<InfosDialogComponent>) { }

  
  closeDialog(){
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
