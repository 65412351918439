import { Component, OnInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { InfosDialogComponent } from '../infos-dialog/infos-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private matDialog: MatDialog) { }

  openDialog() {
    this.matDialog.open(InfosDialogComponent, {
      height: '90%',
      width: '90%',
    });
  }

  ngOnInit(): void {
  }

}
